import React from 'react'

import Navbar from '../components/navbar/Navbar'
import DETOX from '../components/detox/Detox'
import DETOXSECTION from '../components/detox/DetoxSection'
import InsuranceSection from '../components/insurance/InsuranceSection';
import ContactForm from '../components/contact/ContactForm';
import Footer from '../components/footer/Footer'

const DetoxPage = () => {
    return (
        <>
           <Navbar /> 
           <DETOX />
           <DETOXSECTION />
           <InsuranceSection />
           <ContactForm />
           <Footer />
        </>
    )
}

export default DetoxPage
