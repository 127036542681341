import React from "react";
import Fade from 'react-reveal/Fade';
import './About.css'
import {Helmet} from "react-helmet";


const About = () => {

    return (
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>About Us - HEALING PATH DETOX</title>
                <link rel="canonical" href="https://www.healingpathdetox.org/about-us" />
            </Helmet>



            <div className='about main-menu'>

                        <div className="about-content">
                            <Fade top>
                                <h1>ABOUT US</h1>
                            </Fade>
                        </div>
                    </div>


            </>
    )

    }
export default About
