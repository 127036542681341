import React from 'react'
import Fade from 'react-reveal/Fade';
import './SA.css'
import {Helmet} from "react-helmet";

const SA = () => {
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Common Abused Drugs - HEALING PATH DETOX</title>
            <link rel="canonical" href="https://www.healingpathdetox.org/substance-abuse" />
        </Helmet>
        <div className='sa submenu'>

                <div className="sa-content">
                    <Fade top>
                    <h1>COMMON ABUSED DRUGS</h1>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default SA
