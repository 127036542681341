import React from 'react'
import Fade from 'react-reveal/Fade';
import './DetoxCocaine.css'
import {Helmet} from "react-helmet";

const DetoxCocaine = () => {
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Cocaine Detox - HEALING PATH DETOX</title>
        <link rel="canonical" href="https://www.healingpathdetox.org/detox-cocaine" />
        </Helmet>

        <div className='detox-cocaine'>

                <div className="detox-cocaine-content">
                    <Fade top>
                    <h1>Cocaine Detox</h1>
                    <h2>Detox Program</h2>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default DetoxCocaine
