import React from 'react'
import './Hero.css'
import Fade from 'react-reveal/Fade';

const Hero = () => {
    return (
<>        
<div className='hero'>

    <div className="hero-container">


        <div className="content">

     

                <div className='content-caption'>

                <Fade left>
                <h1>Healing Path Detox</h1>
                </Fade>
                <Fade right>
                <h2>Renew Your Life: Start Fresh at Healing Path Detox</h2>
                </Fade>

                </div>



        </div>

    </div>
</div>
</>

    )
}

export default Hero